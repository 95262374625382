import React from "react";
import logo from "../logo.png";
import { ReactComponent as InstagramIcon } from "../icons/instagram.svg";
import { ReactComponent as YouTubeIcon } from "../icons/youtube.svg";

export const HomeContent = () => {
  return (
    <div>
      <div>
        <img src={logo} className="w-48 m-auto" alt="Lotus Coconut Logo" />
        <div className="text-center mt-10 sm:mt-10 ax-w-xl m-auto sm:w-3/5">
          <div className="text-sand">
            <h1 className="text-5xl font-serif">Noah Fernandes</h1>
            <h2 className="mt-2 text-lg sm:text-xl font-san-serif">
              Mindful Coaching & Music Therapy
            </h2>
          </div>
          <div className="mt-8 sm:mt-10">
            <a
              className="bg-mud text-lg text-sand inline-block py-2 px-4 uppercase rounded-md"
              href="https://calendly.com/noah-fernandes/30min"
              target="_new"
            >
              Book a free discovery call
            </a>
          </div>
          <div className="mt-10 flex space-x-6 justify-center">
            <a href="https://www.instagram.com/noahdecoco/" target="_new">
              <InstagramIcon className="w-6 fill-sand" />
            </a>
            <a href="https://www.youtube.com/@Noah-Fernandes" target="_new">
              <YouTubeIcon className="w-6 fill-sand" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
