import { AboutContent } from "./components/AboutContent.jsx";
import { AppFooter } from "./components/common/AppFooter.jsx";
import { AppHeader } from "./components/common/AppHeader.jsx";
import { AppSection } from "./components/common/AppSection.jsx";
import { HomeContent } from "./components/HomeContent.jsx";
import { SectionQuote } from "./components/SectionQuote.jsx";
import { ServicesContent } from "./components/ServicesContent.jsx";
import { TestimonialsContent } from "./components/TestimonialsContent.jsx";
import { ContactContent } from "./components/ContactContent.jsx";

import "./App.css";

function App() {
  return (
    <div className="App w-screen h-screen bg-sand overflow-auto relative">
      <AppSection
        id="home"
        className="bg-gradient-to-r from-gray-950 via-slate-900 to-gray-950 flex items-center"
      >
        <HomeContent />
      </AppSection>

      <AppFooter />
    </div>
  );
}

export default App;
