import React from "react";

export const AppFooter = () => {
  return (
    <footer className="bg-neutral-950">
      <div className="container text-center text-sand font-light text-sm p-4">
        <p>©2025 by Noah Fernandes</p>
      </div>
    </footer>
  );
};
